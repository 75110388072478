<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card" v-if="showForm1">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img
              :src="require('@/assets/images/logo-wide.png')"
              max-height="200px"
              max-width="200px"
              alt="logo"
              contain
              class="me-3"
            ></v-img>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2 text-center">Request Reset Password</p>
          <p class="mb-2 text-center" v-if="!pageAlert.show">Please enter your E-Mail Address to request a confirmation code.</p>
          <v-alert
            v-if="pageAlert.show"
            border="left"
            class="my-2"
            :type="pageAlert.type"
            dismissible
          >{{ pageAlert.message }}</v-alert>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation v-on:submit.prevent>
            <v-text-field
              v-model="email"
              :rules="emailRules"
              outlined
              label="Email"
              placeholder="Email"
              hide-details
              class="mb-3"
              v-on:keyup.enter="requestConfirmationCode"
            ></v-text-field>

            <v-btn block :disabled="!valid" @click="requestConfirmationCode" ref="btnLogin" color="primary" class="mt-6">Next</v-btn>

            <div class="d-flex align-center justify-space-between flex-wrap mt-2  text-center">
              <!-- <v-checkbox label="Remember Me" hide-details class="me-3 mt-1"></v-checkbox> -->

              <!-- forgot link -->
              <v-btn to="/login" text link block class="mt-1">Back to Login page</v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
      
      <v-card class="auth-card" v-if="!showForm1">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img
              :src="require('@/assets/images/logo-wide.png')"
              max-height="200px"
              max-width="200px"
              alt="logo"
              contain
              class="me-3"
            ></v-img>

            <h2 class="text-3xl font-weight-semibold">Beton</h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2 text-center">Request Reset Password</p>
          <p class="mb-2 text-center" v-if="!pageAlert.show">Please enter your E-Mail Address to request a confirmation code.</p>
          <v-alert
            v-if="pageAlert.show"
            border="left"
            class="my-2"
            :type="pageAlert.type"
            dismissible
          >{{ pageAlert.message }}</v-alert>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form ref="form3" v-model="valid3" lazy-validation v-on:submit.prevent>
            <v-text-field
              v-model="newPassword"
              :rules="newPasswordRules"
              outlined
              label="New Password"
              placeholder="New Password"
              hide-details
              class="mb-3"
              type="password"
            ></v-text-field>
            <v-text-field
              v-model="confPassword"
              :rules="confPasswordRules"
              outlined
              label="Confirm Password"
              placeholder="Confirm Password"
              hide-details
              class="mb-3"
              type="password"
            ></v-text-field>

            <v-btn block :disabled="!valid3" @click="changePassword" ref="btnLogin" color="primary" class="mt-6">Next</v-btn>

            <div class="d-flex align-center justify-space-between flex-wrap mt-2  text-center">
              <!-- <v-checkbox label="Remember Me" hide-details class="me-3 mt-1"></v-checkbox> -->

              <!-- forgot link -->
              <v-btn to="/login" text link block class="mt-1">Back to Login page</v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
    

    <v-dialog v-model="dialog" persistent max-width="350">
      <v-form v-model="valid2" ref="form2" v-on:submit.prevent>
        <v-card>
          <v-card-title class="text-h5">Enter Verification Code</v-card-title>
          <v-card-text
            >A Verification Code has been sent to <b>{{ email }}</b>, please enter it to verify your
            account.</v-card-text
          >
          <v-card-text>
            <v-text-field
              v-model="verificationCode"
              :rules="verificationCodeRules"
              counter="6"
              required
              label="Verification Code"
              outlined
            ></v-text-field>
            <a @click="requestConfirmationCode">Re-send verification code.</a>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" outlined @click="confirmCode">Confirm</v-btn>
            <v-btn color="green darken-1" text @click="dialog = false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  data: function() {
    return {
      showForm1: true,
      pageAlert: {
        show: false,
        message: "",
        type: ""
      },
      valid: false,
      email: "",
      emailRules: [v => !!v || "Email is required"],
      iToken: "",

      dialog: false,
      valid2: false,
      verificationCode: '',
      verificationCodeRules: [v => !!v || "Code is required"],
      
      valid3: false,
      newPassword: '',
      confPassword: '',
      newPasswordRules: [v => !!v || "New Password is required"],
      confPasswordRules: [v => !!v || "Confirm Password is required"],
    };
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    validate2() {
      this.$refs.form2.validate();
    },
    reset2() {
      this.$refs.form2.reset();
    },
    resetValidation2() {
      this.$refs.form2.resetValidation();
    },
    validate3() {
      this.$refs.form3.validate();
    },
    reset3() {
      this.$refs.form3.reset();
    },
    resetValidation3() {
      this.$refs.form3.resetValidation();
    },
    requestConfirmationCode() {
      this.validate();
      if (this.valid == true) {
        
        if(this.email == ""){
          this.$awn.warning('email is required')
          return;
        }

        var formData = new FormData();
        formData.append("email", this.email);

        let req = this.$axios.post("forgot/request", formData)
        let res = (res => {
          if (res.data.status == "success") {
            this.dialog = true
            this.showAlert(res.data.alert, res.data.message)
            // this.showAlert('success', 'A Verification Code has been sent to , please enter it to verify your account.')
          } else {
            this.showAlert(res.data.alert, res.data.message)
          }
        })
        
        this.$awn.asyncBlock(req, res)
      }
    },
    confirmCode() {
      this.validate2();
      if (this.valid2 == true) {
        
        
        if(this.email == ""){
          this.$awn.warning('email is required')
          return;
        }
        
        if(this.verificationCode == ""){
          this.$awn.warning('Code is required')
          return;
        }
        
        var formData = new FormData();
        formData.append("email", this.email);
        formData.append("code", this.verificationCode);

        let req = this.$axios.post("forgot/verify", formData)
        let res = (res => {
          if (res.data.status == "success") {
            this.dialog = false
            this.showForm1 = false
            this.showAlert(res.data.alert, res.data.message)
          } else {
            this.showAlert(res.data.alert, res.data.message)
          }
        });
        
        this.$awn.asyncBlock(req, res)
      }
    },
    changePassword(){

      this.validate3();
      if (this.valid3 == true) {
        
        
        if(this.email == ""){
          this.$awn.warning('email is required!')
          return;
        }
        
        if(this.verificationCode == ""){
          this.$awn.warning('Verification Code is required!')
          return;
        }
        
        if(this.newPassword == ""){
          this.$awn.warning('New Password is required!')
          return;
        }
        
        if(this.confPassword == ""){
          this.$awn.warning('Confirm Password is required!')
          return;
        }
        
        if(this.newPassword !== this.confPassword){
          this.$awn.warning('New Password and Confirm Password doesnt match!')
          return;
        }
        
        var formData = new FormData();
        formData.append("email", this.email);
        formData.append("code", this.verificationCode);
        formData.append("password", this.newPassword);

        let req = this.$axios.post("forgot/change_password", formData)
        
        let res = (res => {
          if (res.data.status == "success") {
            var resData = res.data;
            alert(resData.message)
            this.$router.push("/login");
          } else {
            this.showAlert(res.data.alert, res.data.message)
          }
        });
        
        this.$awn.asyncBlock(req, res)
      }
    }
  },
  watch: {
    iToken(newVal) {
      localStorage.appToken = newVal;
    }
  },
  beforeCreate() {
    this.$store.state.showNavBar = false;
    this.$store.state.hideFooter = true;
  },
  beforeMount() {},
  mounted() {},
  setup() {
    const isPasswordVisible = ref(false)
    const email = ref('')
    const password = ref('')
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    return {
      isPasswordVisible,
      email,
      password,
      socialLink,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
